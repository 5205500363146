<template>
  <div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <div></div>
        <div>
          <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
          <el-button
            type="primary"
            @click="outExeAll('groupList')"
            >전체출력</el-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:user_username="slotProps">
          <div>
            {{ slotProps.callback.row.user.username }}
          </div>
        </template>
      <template v-slot:subject_name="slotProps">
          <div>
            {{ slotProps.callback.row.subject.name }}
          </div>
        </template>
        <template v-slot:chapter_name="slotProps">
          <div>
            {{ slotProps.callback.row.chapter.name }}
          </div>
        </template>
        <template v-slot:type="slotProps">
          <div>
            {{ type(slotProps.callback.row.type) }}
          </div>
        </template>
        <template v-slot:operation="slotProps">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').check"
            placement="top"
          >
            <el-button
              @click="handleClick('check', slotProps.callback.row)"
              icon="el-icon-view"
              type="success"
              size="mini"
            ></el-button>
          </el-tooltip>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import { groupList } from "@/api/index";
export default {
  name: "pass",
  mixins: [mixin_list(groupList)],
  components: {
    myTable,
    page,
  },
  data() {
    return {
      filter: {
        search: "",
      },
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        {
          label: this.$t("group.create_time"),
          sortable: true,
          prop: "create_time",
        },
        {
          label: this.$t("group.update_time"),
          sortable: true,
          prop: "update_time",
        },
        {
          label: this.$t("group.user_username"),
          sortable: true,
          prop: "user.username",
          slot: "user_username",
        },
        {
          label: this.$t("group.name"),
          sortable: true,
          prop: "name",
        },
        {
          label: this.$t("group.subject_name"),
          sortable: true,
          prop: "subject.name",
          slot: "subject_name",
        },
        {
          label: this.$t("group.chapter_name"),
          sortable: true,
          prop: "chapter.name",
          slot: "chapter_name",
        },
        {
          label: this.$t("group.timer"),
          sortable: true,
          prop: "timer",
        },
        {
          label: this.$t("group.type"),
          sortable: true,
          slot: "type",
          prop: "type",
        },
        {
          label: this.$t("operation"),
          prop: "",
          width: 300,
          slot: "operation",
        },
      ],
    };
  },
  computed: {},
  methods: {
    type(type) {
      switch (type) {
        case 1:
          return this.$t("group.type1");
        case 2:
          return this.$t("group.type2");
      }
    },
    async handleClick(type, data) {
      switch (type) {
        case "check":
          this.$router.push(`/group/detail?id=${data.id}`);
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>